@media only screen and (min-width: 849px) {
  .slide img {
    width: auto !important;
    height: auto !important;
    max-width: 100% !important;
    max-height: 400px !important;
  }
  .slider-container {
    max-width: 680px;
    flex: 1;
  }
}
@media only screen and (max-width: 848px) {
  .slide img {
    max-width: 100% !important;
    height: unset !important;
    width: auto !important;
    max-height: 300px !important;
  }
  .slider-container {
    max-width: 85%;
    flex: 1;
  }
}
