html,
body,
#root {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  overscroll-behavior-y: none;
  -webkit-touch-callout: none;
}

.Toastify__toast--default {
  min-height: 48px;
  background-color: rgba(255, 255, 255, 0.87);
  color: #000;
  margin-bottom: env(safe-area-inset-bottom);
}

.recharts-label-list text {
  font-size: 10px;
  font-family: 'Inter', sans-serif;
}

@font-face {
  font-family: 'AGFatumCBold';
  src: local('AGFatumC'), url('./assets/fonts/AGFatumC-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'AGFatumCBoldItalic';
  src: local('AGFatumCBoldItalic'), url('./assets/fonts/AGFatumC-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'AGFatumCItalic';
  src: local('AGFatumCItalic'), url('./assets/fonts/AGFatumC-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'AGFatumC';
  src: local('AGFatumC'), url('./assets/fonts/AGFatumC.otf') format('opentype');
}

@font-face {
  font-family: 'AGFatumCExtraBoldItalic';
  src: local('AGFatumCExtraBoldItalic'), url('./assets/fonts/AGFatumExtraBoldC-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'AGFatumCExtraBold';
  src: local('AGFatumCExtraBold'), url('./assets/fonts/AGFatumExtraBoldC.otf') format('opentype');
}
