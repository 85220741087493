.editor {
  box-sizing: border-box;
  border: 1px solid #2e2e2e;
  cursor: text;
  padding: 16px;
  border-radius: 4px;
  box-shadow: inset 0px 1px 8px -3px #1a1a1a;
  background: #202020;
  width: 100%;
  max-width: calc(100% - 48px);
}

.editor :global(.DraftEditor-root) {
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
}

/* .editor :global(.public-DraftEditor-content) {

} */
.editor :global(.public-DraftEditorPlaceholder-root) {
  position: absolute;
  z-index: 1;
  color: #cac4d0;
}
